<template>
  <div class="home">
    <NavBarVue />
    <Hero />
    <CarBrands />
    <Cars />
    <FooterVue />
  </div>
</template>

 <script>
import NavBarVue from "@/components/Includes/NavBar.vue";
import FooterVue from "@/components/Includes/Footer.vue";
import Hero from "@/components/Hero.vue";
import CarBrands from "@/components/CarBrands.vue";
import Cars from "@/components/Cars.vue";

export default {
  name: "HomeView",
  components: {
    NavBarVue,
    FooterVue,
    Hero,
    CarBrands,
    Cars
  },
};
</script>
