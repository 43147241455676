<template>
    <section class="car-brands">
        <h1>Car Brands</h1>
        <p>Explore our collection of brand new cars, tokunbo and Nigerian-used cars.</p>
        <div class="car-brands-item">
            <div  class="car-brands-logo">
                <img src="../assets/acura.png">
                <p>Acura</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/audi.png">
                <p>Audi</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/benz.png">
                <p>Mercedes Benz</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/bmw.png">
                <p>BMW</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/chevrolet.png">
                <p>Chevrolet</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/ford.png">
                <p>Ford</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/honda.png">
                <p>Honda</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/hyundia.png">
                <p>Hyundia</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/kia.png">
                <p>KIA</p>
            </div>
            <div class="car-brands-logo">
                <img src="../assets/lexus.png">
                <p>Lexus</p>
            </div>
        </div>
    </section>
</template>