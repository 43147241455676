<template>
    <section class="our-cars">
        <h1>Our Cars</h1>
        <p>Explore our collection of tokunbo cars.</p>
        <div class="cars-flex">
            <div class="cars-flex-content">
                <img src="../assets/WhatsApp Image 2023-10-19 at 13.54.15 (1).jpeg">
                <p>Toyota Venza</p>
                <p class="button">2012</p>
            </div>
             <div class="cars-flex-content">
                <img src="../assets/WhatsApp Image 2023-10-19 at 13.54.15.jpeg">
                 <p>Toyota Highlander</p>
                <p class="button">2009</p>
            </div>
             <div class="cars-flex-content">
                <img src="../assets/WhatsApp Image 2023-10-19 at 14.19.22.jpeg">
                <p>RAV4</p>
                <p class="button">2005</p>
            </div>
             <div class="cars-flex-content">
                <img src="../assets/WhatsApp Image 2023-10-19 at 13.54.16.jpeg">
                <p>Toyota Venza</p>
                <p class="button">2012</p>
            </div>
        </div>
    </section>
</template>