<template>
  <router-view />
</template>
<script>


export default {
  setup() {
    
  },
}
</script>
<style>
@import "./styles/index.css";
</style>



