<template>
    <footer class="footer">
        <img src="../../assets/main-logo.png" width="150">
        <p style="color: #fff; font-size: 14px;">© {{ year }} Dida sea base auto all right reserved. Developed by 08028192337</p>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getUTCFullYear(),
        };
    },
};
</script>
