<template>
  <nav class="navigation">
    <router-link to="/">
      <img src="../../assets/logo.png" width="50">
    </router-link>
    <ul class="nav-links">
        <li><router-link to="/">Home</router-link> </li>
        <li><router-link to="/about">About</router-link> </li>
        <li><router-link to="/products">Products</router-link> </li>
        <li><router-link to="/contact-us">Contact Us</router-link> </li>
    </ul>
    <a href="#" class="get-in-touch">Get In Touch</a>
    <i class="ri-menu-line menu-icon" @click="toggleMobileMenu"></i>
  </nav>

  <div class="mobile-menu">
     <ul class="mobile-nav-links" :class="{ 'open': isMobileMenuOpen }">
        <li><router-link to="/">Home</router-link> </li>
        <li><router-link to="/about">About</router-link> </li>
        <li><router-link to="/products">Products</router-link> </li>
        <li><router-link to="/contact-us">Contact Us</router-link> </li>
        <a href="#" class="get-in-touch">Get In Touch</a>
    </ul>
  </div>
</template>

<script>
      
// import { VueMarqueeSlider } from 'vue3-marquee-slider';
// import '../../../node_modules/vue3-marquee-slider/dist/style.css'
export default {
  data() {
    return {
      isMobileMenuOpen: false
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      console.log(this.isMobileMenuOpen)
    }
  }
}
</script>